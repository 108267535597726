<template>
  <v-card class="pa-2" flat style="height: 100%;">

    <div class="d-flex justify-space-between mb-2 mx-2">
      <p class="pb-0 mb-2 font-weight-medium" style="font-size: 20px;">
        <span v-if="internalIsEdit">
          <i class="mdi mdi-table-edit"></i>
        </span>
        <span v-else>
          <i class="mdi mdi-table"></i>
        </span>
        {{ internalIsEdit ? 'Edit' : 'Create' }} Data
      </p>
      <v-btn
        icon
        color="primary"
        @click="closeModal"
      >

        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-text class="px-2" style="max-height: 70vh">
      <v-row no-gutters>
        <v-form ref="form" v-model="validForm" style="width: 100%">
          <v-row no-gutters>
            <v-col v-for="(field, n) in allFields" :key="field.name" cols="12" lg="6">
              <!-- Display warning for non-VARCHAR primary key -->
              <div v-if="field.primaryKey && field.dataType !== 'VARCHAR' && !internalIsEdit" class="mb-2" style="font-size: 15px;">
                Primary key field must not be entered; it will be assigned automatically
              </div>

              <!-- Render input fields based on data type -->
              <v-text-field
                v-if="field.dataType === 'VARCHAR' && !isTextArea(field) && (!field.primaryKey || (field.primaryKey && !internalIsEdit))"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                outlined
                clearable
                dense
                :class="n % 2 ? 'pl-lg-1' : 'pr-lg-1'"
                :prepend-inner-icon="field.primaryKey ? 'mdi-key' : ''"
                :readonly="field.primaryKey && internalIsEdit"
                :disabled="field.primaryKey && internalIsEdit"
                :rules="field.required ? [(v) => !!v || 'Required'] : []"
              />
              <v-textarea
                v-else-if="field.dataType === 'VARCHAR' && isTextArea(field) && (!field.primaryKey || (field.primaryKey && !internalIsEdit))"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                outlined
                clearable
                dense
                auto-grow
                :class="n % 2 ? 'pl-lg-1' : 'pr-lg-1'"
                :prepend-inner-icon="field.primaryKey ? 'mdi-key' : ''"
                :readonly="field.primaryKey && internalIsEdit"
                :disabled="field.primaryKey && internalIsEdit"
                :rules="field.required ? [(v) => !!v || 'Required'] : []"
              />
              <v-text-field
                v-else-if="field.dataType !== 'BOOLEAN' && field.dataType !== 'TIMESTAMP' && field.dataType !== 'JSON' && !['INTEGER', 'BIGINT', 'DOUBLE', 'FLOAT'].includes(field.dataType)"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                outlined
                clearable
                dense
                :class="n % 2 ? 'pl-lg-1' : 'pr-lg-1'"
                :prepend-inner-icon="field.primaryKey ? 'mdi-key' : ''"
                :readonly="field.primaryKey && internalIsEdit"
                :disabled="field.primaryKey && internalIsEdit"
                :rules="field.required ? [(v) => !!v || 'Required'] : []"
              />
              <v-text-field
                v-else-if="['INTEGER', 'BIGINT', 'DOUBLE', 'FLOAT'].includes(field.dataType)"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                type="number"
                outlined
                dense
                :prepend-inner-icon="field.primaryKey ? 'mdi-key' : ''"
                :class="n % 2 ? 'pl-lg-1' : 'pr-lg-1'"
                :disabled="field.primaryKey"
                :readonly="field.primaryKey"
                :rules="!field.primaryKey && field.required ? [(v) => !!v || 'Required'] : []"
              />
              <v-checkbox
                v-if="field.dataType === 'BOOLEAN'"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                :disabled="field.primaryKey"
                dense
              />
              <v-datetime-picker
                v-if="field.dataType === 'TIMESTAMP'"
                v-model="internalFormData[field.name]"
                :label="getFieldLabel(field)"
                outlined
                dense
                clearable
                hide-details
                :disabled="field.primaryKey"
                :text-field-props="{ outlined: true, dense: true, rules: field.required ? [(v) => !!v || 'Required'] : [], class: n % 2 ? 'pl-lg-1' : 'pr-lg-1' }"
              >
                <template v-slot:dateIcon="{}">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon="{}">
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
              <div v-if="field.dataType === 'JSON'" class="d-flex align-center">
                <v-text-field
                  v-model="internalFormData[field.name]"
                  :label="getFieldLabel(field)"
                  outlined
                  dense
                  readonly
                  clearable
                  hide-details
                  :class="n % 2 ? 'pl-lg-1' : 'pr-lg-1'"
                  append-icon="mdi-pencil"
                  @click:append="openJsonEditor(field)"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </v-card-text>

    <div class="d-flex mr-2 my-2">
      <v-btn
        outlined
        color="primary"
        @click="closeModal"
      >
        <v-icon class="mr-2">mdi-cancel</v-icon>
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        @click="saveEntry"
      >
        <v-icon class="mr-2">mdi-floppy</v-icon>
        {{ $lang.actions.save }}
      </v-btn>

    </div>
    <!-- JSON Editor Dialog -->
    <v-dialog v-model="jsonEditorModal" max-width="75%" class="json-dialog">
      <v-card class="pa-2" flat>
        <p class="pb-0 mb-0">{{ currentJsonFieldName }}</p>
        <codemirror v-model="currentJsonFieldValue" :options="cmOptions" />
        <div class="d-flex w-100 justify-end mt-1">
          <v-btn
            outlined
            color="primary"
            @click="closeJsonEditor"
          >
            <v-icon class="mr-2">mdi-floppy</v-icon>
            {{ $lang.actions.save }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'
import { postEntityEntry, putEntityEntry } from '@/services/dataBrowsing/dataBrowsing'
import { format  } from 'date-fns'

export default {
  components: {
    codemirror
  },
  props: {
    isEdit: {
      type: Boolean,
      required: true
    },
    allFields: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    selectedEntity: {
      type: String,
      required: true
    },
    primaryKeyValue: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      lock: true,
      internalIsEdit: this.isEdit,
      internalFormData: { ...this.formData },
      jsonEditorModal: false,
      currentJsonFieldName: '',
      currentJsonFieldValue: '',
      cmOptions: {
        gutters: [],
        tabSize: 4,
        mode: 'javascript',
        theme: this.$vuetify.theme.dark ? 'ayu-mirage' : '3024-day',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true
      },
      validForm: true
    }
  },
  computed: {
    isTextArea() {
      return (field) => field.dataType === 'VARCHAR' && this.internalFormData[field.name]?.length > 50
    }
  },
  watch: {
    isEdit(val) {
      this.internalIsEdit = val
    },
    formData: {
      handler(newFormData) {
        this.internalFormData = { ...newFormData }
        this.reformatTimestampFields()
      },
      deep: true
    }
  },
  created() {
    this.reformatTimestampFields()
  },
  methods: {
    reformatTimestampFields() {
      for (const field of this.allFields) {
        if (field.dataType === 'TIMESTAMP' && this.internalFormData[field.name]) {
          const date = new Date(this.internalFormData[field.name])

          if (!isNaN(date)) {
            this.internalFormData[field.name] = date
          }
        }
      }
      this.lock = false
    },
    reverseFormatTimestampFields() {
      for (const field of this.allFields) {
        if (field.dataType === 'TIMESTAMP' && this.internalFormData[field.name]) {
          const newTs = format(this.internalFormData[field.name], 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')

          if (!isNaN(newTs)) {
            this.internalFormData[field.name] = newTs
          }
        }
      }
    },
    openJsonEditor(field) {
      this.currentJsonFieldName = field.name
      this.currentJsonFieldValue = this.internalFormData[field.name]
      this.jsonEditorModal = true
    },
    closeJsonEditor() {
      this.internalFormData[this.currentJsonFieldName] = this.currentJsonFieldValue
      this.jsonEditorModal = false
    },
    closeModal() {
      this.$emit('close')
    },
    async saveEntry() {
      this.$refs.form.validate()
      this.reverseFormatTimestampFields()

      const invalidJsonFields = []

      for (const field of this.allFields) {
        if (field.dataType === 'JSON' && this.internalFormData[field.name]) {
          try {
            JSON.parse(this.internalFormData[field.name])
          } catch (e) {
            invalidJsonFields.push(field.name)
          }
        }
        if (['INTEGER', 'BIGINT', 'DOUBLE', 'FLOAT'].includes(field.dataType)) {
          this.internalFormData[field.name] = parseFloat(this.internalFormData[field.name])
        }
      }

      if (invalidJsonFields.length > 0) {
        this.$emit('show-snackbar', {
          message: `${this.$lang.errors.invalidJsonInFields}: ${invalidJsonFields.join(', ')}`,
          timeout: 3000,
          color: 'error'
        })

        return
      }

      try {
        if (!this.areRequiredFieldsFilled()) {
          this.$emit('show-snackbar', {
            message: this.$lang.errors.fillRequiredFields,
            timeout: 1250,
            color: 'error'
          })

          return
        }
        this.convertDateTimeFields()
        this.parseJsonFields()

        const res = this.internalIsEdit ? await putEntityEntry(this.selectedEntity, this.primaryKeyValue, this.internalFormData) : await postEntityEntry(this.selectedEntity, this.internalFormData)

        if (res.status && res.status !== 200) {

          switch (res.status) {
          case 403:
            this.$emit('show-snackbar',{
              message: this.$lang.errors.unauthorized,
              timeout: 3000,
              color: 'error'
            })

            return
          case 501:
            this.$emit('show-snackbar',{
              message: this.$lang.errors.noShadowObject,
              timeout: 3000,
              color: 'error'
            })

            return
          default:
            break
          }

          this.$emit('show-snackbar', {
            message: res.statusText || this.$lang.errors.somethingWentWrong,
            timeout: 3000,
            color: 'error'
          })

          return
        }

        this.$emit('show-snackbar', {
          message: this.internalIsEdit ? this.$lang.errors.fieldEditedSuccessfully : this.$lang.errors.fieldCreatedSuccessfully,
          timeout: 3000,
          color: 'success'
        })

        this.$emit('refresh-data')
        this.closeModal()
      } catch (err) {
        this.$emit('show-snackbar', {
          message: err,
          timeout: 3000,
          color: 'error'
        })
        console.error(err)
      }
    },
    getFieldLabel(field) {
      return field.required ? `${field.name} *` : field.name
    },
    resetFormData() {
      this.$emit('reset-form-data')
    },
    convertDateTimeFields() {
      for (const field of this.allFields) {
        if (field.dataType === 'TIMESTAMP' && this.internalFormData[field.name]) {
          const date = new Date(this.internalFormData[field.name])

          if (!isNaN(date)) {
            this.internalFormData[field.name] = date
          }
        }
      }
    },
    parseJsonFields() {
      for (const field of this.allFields) {
        if (field.dataType === 'JSON') {
          if (this.internalFormData[field.name] === null || this.internalFormData[field.name] === '') {
          // Send an empty object if the JSON field is null or empty
            this.internalFormData[field.name] = null
          } else {
            try {
              this.internalFormData[field.name] = JSON.parse(this.internalFormData[field.name])
            } catch (e) {
              console.error(`Error parsing JSON field "${field.name}":`, e)
              this.$emit('show-snackbar', {
                message: `${this.$lang.errorParsingJsonFields} "${field.name}": ${e}`,
                timeout: 3000,
                color: 'error'
              })
            }
          }
        }
      }
    },
    areRequiredFieldsFilled() {
      for (const field of this.allFields) {

        if (field.primaryKey) continue

        if (field.dataType === 'BOOLEAN') {
          if (this.internalFormData[field.name] !== true && this.internalFormData[field.name] !== false) {
            this.internalFormData[field.name] = false
          }
        }

        const value = this.internalFormData[field.name]

        if (field.required) {
          if (field.dataType === 'TIMESTAMP') {
            if (!value) {
              return false
            }
          } else {
            if (value === undefined || value === null || value === '') {
              return false
            }
          }
        }
      }

      return true
    }
  }
}
</script>

  <style>
  .ellipsis-field input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vue-codemirror {
    height: 40vh;
    width: 100%;
  }
  .CodeMirror-line > span {
    padding-left: 20px;
  }

  </style>
